<template>
    <div>

        <!-- VIEW CAMERA FEED -->
        <CameraFeedModal v-model="liveStream.modal" :liveStream="liveStream" @hideVideoModal="hideVideoModal"/>

        <!-- MAP MODAL -->
        <MapCoordinatesPickerModal v-if="geoLocationMapModal" v-model="geoLocationMapModal" @pointSelected="applyLatLng"
                                   :oldLat="curDeviceCoordinates.lat" :oldLng="curDeviceCoordinates.lng"/>

        <!-- REBOOT DEVICE -->
        <b-modal class="modal-dark" v-model="rebootModal" hide-header hide-footer>
            <h3 class="d-flex justify-content-center text-center" style="font-weight: bold">
                Are you sure you want to reboot this device?
            </h3>
            <div class="w-100 d-flex justify-content-center" style="gap: 10px">
                <b-button size="sm" :disabled="deviceRebooting" variant="secondary" @click="rebootModal = false"
                          style="width: 90px">
                    Cancel
                </b-button>
                <b-button size="sm" :disabled="deviceRebooting" variant="danger" @click="rebootDevice()"
                          style="width: 90px; gap: 3px" class="d-flex justify-content-center align-items-center">
                    <div v-if="!deviceRebooting">Confirm</div>
                    <fa-icon v-else :icon="['fas', 'spinner']" spin style="cursor: default"/>
                    <div v-if="deviceRebooting">Rebooting</div>
                </b-button>
            </div>
        </b-modal>

        <!-- EDIT MODAL -->
        <b-modal no-close-on-backdrop scrollable title="Edit Info" class="modal-dark" v-model="openEditInfo">

            <!-- TABS -->
            <b-tabs style="margin: -6px -16px -16px -16px">

                <!-- DEVICE TAB -->
                <b-tab title="Device" active>

                    <!-- NAME FIELDS -->
                    <div class="mFieldsetTitle"> Device Name </div>
                    <div class="mFieldset">
                        <div class="d-flex align-items-center"
                             style="gap: 5px"> Name:
                            <b-form-input placeholder='Device Name' @keyup.enter="editEnterButton"
                                          v-model="currentDevice.getDevice().friendlyName" size="sm"/>
                        </div>
                        <div v-if="currentDevice.getMultiSensorStreams().length > 1" style="padding-left: 15px">
                            <div v-for="i in currentDevice.getMultiSensorStreams()" class="d-flex align-items-center"
                                 style="gap: 5px">
                                <div style="white-space: nowrap"><b>{{ "Sensor " + i.sourceToken }}:</b></div>
                                <b-form-input :placeholder='i.placeholder || i.name' @keyup.enter="editEnterButton"
                                              v-model="i.friendlyName" size="sm"/>
                            </div>
                        </div>
                    </div>

                    <!-- NETWORKING FIELDS -->
                    <div class="mFieldsetTitle"> Networking </div>
                    <div class="mFieldset">
                        <div class="d-flex align-items-center" style="gap: 5px">
                            <div>IPv4:</div>
                            <b-form-select v-model="deviceOptions.networkConfigValue" :options="networkConfigOptions" style="margin-top: 5px;"/>
                        </div>
                        <div v-show="deviceOptions.networkConfigValue === 1 || deviceOptions.networkConfigValue === 2">
                            <div v-show="deviceOptions.networkConfigValue === 2">
                                <div class="d-flex justify-content-between" style="padding-top: 10px; gap: 10px">
                                    <div>
                                        <span>IP address</span>
                                        <b-input v-model="deviceOptions.ipAddress" placeholder="0.0.0.0" style="margin-top: 5px;"/>
                                    </div>
                                    <div>
                                        <span>Subnet mask</span>
                                        <b-input v-model="deviceOptions.subnetMask" placeholder="255.255.255.0" style="margin-top: 5px;"/>
                                    </div>
                                </div>
                                <div style="padding-top: 10px;">
                                    <span>Default router</span>
                                    <b-input v-model="deviceOptions.defaultRouter" placeholder="192.168.0.1" style="margin-top: 5px;"/>
                                </div>
                            </div>
                            <div style="padding: 5px 0 5px 5px">
                                <span>Domain Name(s)</span>&nbsp;
                                <fa-icon :icon="['fas', 'circle-plus']" @click="addDomainName" title="Add Domain Name" style="cursor: pointer;"/>
                            </div>
                            <div v-for="(value, index) in deviceOptions.domainNames" :key="'value' + index">
                                <b-input-group style="margin-bottom: 10px">
                                    <b-form-input v-model="deviceOptions.domainNames[index]" placeholder="Domain name"/>
                                    <b-input-group-append @click="removeDomainName(index)" v-if="deviceOptions.domainNames.length > 1" title="Remove Domain Name">
                                        <b-input-group-text style="cursor: pointer;">
                                            <fa-icon :icon="['fas', 'close']"/>
                                        </b-input-group-text>
                                    </b-input-group-append>
                                </b-input-group>
                            </div>
                            <div class="d-flex justify-content-between" style="padding-top: 5px; gap: 10px">
                                <div>
                                    <span>Primary DNS server</span>
                                    <b-input v-model="deviceOptions.primaryDNSServer" placeholder="0.0.0.0" style="margin-top: 5px;"/>
                                </div>
                                <div>
                                    <span>Secondary DNS server</span>
                                    <b-input v-model="deviceOptions.secondaryDNSServer" placeholder="0.0.0.0" style="margin-top: 5px;"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- DATE/TIME FIELDS -->
                    <div class="mFieldsetTitle"> Date/Time </div>
                    <div class="mFieldset">
                        <div style="padding-top: 10px">
                            <div>Connect to NTP-server:</div>
                            <b-form-radio v-for="option in ntpSettingsOptions" v-model="deviceOptions.NTPSettings" name="NTPSettings"
                                          :value="option.value" style="padding-top: 5px;" :key="'NTPSettings' + option.value">
                                {{option.text}}
                            </b-form-radio>
                            <div v-if="deviceOptions.NTPSettings === 1" style="padding-top: 10px; margin-bottom: 10px;">
                                <div class="d-flex justify-content-between" style="gap: 10px">
                                    <div>
                                        <span>Primary NTP Server:</span>
                                        <b-input v-model="deviceOptions.NTPServerIp" placeholder="0.0.0.0" style="margin-top: 5px;"/>
                                    </div>
                                    <div>
                                        <span>Secondary NTP Server:</span>
                                        <b-input v-model="deviceOptions.secondaryNTPServerIp" placeholder="0.0.0.0" style="margin-top: 5px;"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="deviceOptions.NTPSettings === 2" class="d-flex justify-content-between" style="gap: 10px; margin-bottom: 10px; padding-top: 10px">
                            <div>
                                <span>Date:</span>
                                <date-picker v-model="deviceOptions.cameraDate" :config="{format: 'MM/DD/YYYY'}" :readonly="deviceOptions.automaticDateTime" style="margin-top: 5px;"/>
                            </div>
                            <div>
                                <span>Time:</span>
                                <date-picker v-model="deviceOptions.cameraTime" :config="{format: 'h:mm:ss a'}" :readonly="deviceOptions.automaticDateTime" style="margin-top: 5px;"/>
                            </div>
                        </div>
                        <span>Time Zone:</span>
                        <b-form-select v-model="deviceOptions.timeZone" :options="timezoneOptions" style="margin-top: 5px;"/>
                    </div>

                    <!-- GEO-LOCATION FIELDS -->
                    <div class="mFieldsetTitle"> Geo-Location </div>
                    <div class="mFieldset">
                        <gmap-map v-if="curDeviceCoordinates.lat && curDeviceCoordinates.lng" :center="curDeviceCoordinates"
                                  :zoom="20" style="height: 200px; width: 100%" :options="mapOptions">
                            <gmap-marker :position="curDeviceCoordinates" :icon="mtopMarker"/>
                        </gmap-map>
                        <div style="padding-top: 10px; margin-bottom: 10px;">
                            <div class="d-flex justify-content-between" style="gap: 4px">
                                <div style="flex: 3">
                                    <span>Set Latitude Coordinate:</span>
                                    <b-input v-model="currentDevice.getDevice().latitude" placeholder="0" style="margin-top: 5px;"/>
                                </div>
                                <div style="flex: 3">
                                    <span>Set Longitude Coordinate:</span>
                                    <b-input v-model="currentDevice.getDevice().longitude" placeholder="0" style="margin-top: 5px;"/>
                                </div>
                                <b-button variant="info" @click="openMap()" style="flex: 1; height: fit-content; align-self: end">
                                    Use Map
                                </b-button>
                            </div>
                        </div>
                    </div>

                </b-tab>

            </b-tabs>

            <!-- FOOTER -->
            <template slot="modal-footer">
                <div class="d-flex justify-content-between w-100">
                    <div class="d-flex" style="gap: 7px">
                        <b-button size="sm" variant="primary" @click="cancelEdit()">Info Page</b-button>
                        <b-button size="sm" variant="primary" @click="updateDeviceData()">
                            Submit
                        </b-button>
                    </div>
                </div>
            </template>

        </b-modal>

        <!-- EDIT EDGE DEVICE INFO -->
        <b-modal no-close-on-backdrop title="Edit Edge Device" class="modal-dark" v-model="editEdgeDeviceNameModal" @close="closeRenameEdgeDeviceModal">
            <template slot="modal-header-close"/>
            <b-input-group prepend="Edge Name">
                <b-form-input v-model="newEdgeDeviceName"/>
            </b-input-group>
            <template slot="modal-footer">
                <div class="d-flex" style="gap: 7px">
                    <b-button size="sm" variant="danger" @click="closeRenameEdgeDeviceModal()">Cancel</b-button>
                    <b-button size="sm" variant="primary" @click="renameEdgeDevice()">
                        Confirm
                    </b-button>
                </div>
            </template>
        </b-modal>

        <!-- DISPLAY EDGE DEVICE INFO -->
        <b-modal no-close-on-backdrop title="Edge Device Info" class="modal-dark" v-model="displayTenantInfoModal" @close="closeTenantInfoModal()">
            <div>
                <b>Tenant Name:</b> {{selectedTenantName}}
            </div>
            <div>
                <b>Edge Device Name:</b> {{selectedEdgeDeviceName}}
            </div>
            <template slot="modal-footer">
                <div class="d-flex" style="gap: 7px">
                    <b-button size="sm" variant="danger" @click="closeTenantInfoModal()">Close</b-button>
                    <b-button size="sm" variant="primary" @click="openRenameEdgeDeviceModal()">
                        Rename Edge Device
                    </b-button>
                </div>
            </template>
        </b-modal>

        <!-- MORE INFO MODAL -->
        <b-modal scrollable title="More Info" class="modal-dark" v-model="openMoreInfo">

            <!-- TABS -->
            <b-tabs v-if="openMoreInfo" style="margin: -6px -16px -16px -16px">

                <!-- DEVICE TAB -->
                <b-tab title="Device" active>

                    <!-- GENERAL -->
                    <div class="d-flex flex-wrap">
                        <TextValueBox class="flexElementFull" text="Device Name" :value="currentDevice.getDeviceName() || '-'"/>
                        <TextValueBox class="flexElement" text="Make" :value="currentDevice.getDeviceMake() || '-'"/>
                        <TextValueBox class="flexElement" text="Model" :value="currentDevice.getDeviceModel() || '-'"/>
                        <TextValueBox class="flexElement" text="Serial Number" :value="currentDevice.getSerialNumber() || '-'"/>
                        <TextValueBox class="flexElement" text="MAC Address" :value="currentDevice.getMACAddress() || '-'"/>
                        <TextValueBox class="flexElement" text="Status" :value="currentDevice.getStatusText()"/>
                        <TextValueBox class="flexElement" text="Device Type" :value="currentDevice.getDeviceType() || '-'"/>
                    </div>

                    <!-- NETWORKING -->
                    <div class="mFieldsetTitle"> Networking </div>
                    <div class="mFieldset d-flex flex-wrap">
                        <TextValueBox class="flexElementFull" text="IPv4" :value="currentDevice.getNetworkConfigText() || '-'"/>
                        <TextValueBox class="flexElement" text="IP Address" :value="currentDevice.getIpAddress() || '-'"/>
                        <TextValueBox class="flexElement" text="Subnet Mask" :value="currentDevice.getMask() || '-'"/>
                        <TextValueBox class="flexElement" text="DNS #1" :value="currentDevice.getDNS(0) || '-'"/>
                        <TextValueBox class="flexElement" text="DNS #2" :value="currentDevice.getDNS(1) || '-'"/>
                        <TextValueBox class="flexElement" text="Default Router" :value="currentDevice.getDefaultRouter() || '-'"/>
                    </div>

                    <!-- DATE/TIME -->
                    <div class="mFieldsetTitle"> Date/Time </div>
                    <div class="mFieldset d-flex flex-wrap">
                        <TextValueBox class="flexElementFull" text="Connect to NTP-server" :value="currentDevice.getNTPSettingText() || '-'"/>
                        <TextValueBox class="flexElement" text="Primary NTP-server" :value="currentDevice.getNTP(0) || '-'"/>
                        <TextValueBox class="flexElement" text="Secondary NTP-server" :value="currentDevice.getNTP(1) || '-'"/>
                        <TextValueBox class="flexElement" text="Camera Date" :value="currentDevice.getDeviceDate().toLocaleDateString() || '-'"/>
                        <TextValueBox class="flexElement" text="Camera Time" :value="currentDevice.getDeviceTime().toLocaleTimeString() || '-'"/>
                        <TextValueBox class="flexElement" text="Time Zone" :value="findTimeZoneNew(currentDevice.getTimeZone()) || '-'"/>
                    </div>

                    <!-- GEO-LOCATION -->
                    <div class="mFieldsetTitle"> Geo-Location </div>
                    <div class="mFieldset d-flex flex-wrap">
                        <gmap-map v-if="curDeviceCoordinates.lat && curDeviceCoordinates.lng" :center="curDeviceCoordinates"
                                  :zoom="20" style="height: 200px; width: 100%" :options="mapOptions">
                            <gmap-marker :position="curDeviceCoordinates" :icon="mtopMarker"/>
                        </gmap-map>
                        <TextValueBox class="flexElement" text="Latitude Coordinate" :value="currentDevice.getLatitude().toString() || '-'"/>
                        <TextValueBox class="flexElement" text="Longitude Coordinate" :value="currentDevice.getLongitude().toString() || '-'"/>
                    </div>

                </b-tab>

                <!-- STREAMS TAB -->
                <b-tab v-if="currentDevice.getArchiveStreams().length > 0" title="Streams">
                    <div class="w-100 d-flex justify-content-end" style="padding-right: 5px">
                        Record
                    </div>
                    <div v-for="stream in currentDevice.getArchiveStreams()" :key="stream.profileToken"
                         class="d-flex justify-content-between w-100" style="margin-bottom: 20px">
                        <div>
                            <div style="font-weight: bold; font-size: 20px">
                                Sensor {{stream.sourceToken}} ({{currentDevice.isRecording() ? "" : "Not "}}Recording)
                            </div>
                            <div>{{stream.friendlyName}}</div>
                        </div>
                        <div class="d-flex align-items-center">
                            <b-button v-if="currentDevice.isRecording()"
                                      :variant="recordingUpdate.find(_ => _ === stream.dataChannel) !== undefined ? 'secondary' : 'danger'"
                                      :disabled="recordingUpdate.find(_ => _ === stream.dataChannel) !== undefined"
                                      @click="startStopArchive(currentDevice, stream.sourceToken, stream.dataChannel)"
                                      title="Stop Recording">
                                <fa-icon v-if="recordingUpdate.find(_ => _ === stream.dataChannel) !== undefined"
                                         :icon="['fas', 'spinner']" spin style="padding: 0 7px;"/>
                                <span v-else>Stop</span>
                            </b-button>
                            <b-button v-else
                                      :variant="recordingUpdate.find(_ => _ === stream.dataChannel) !== undefined ? 'secondary' : 'success'"
                                      :disabled="recordingUpdate.find(_ => _ === stream.dataChannel) !== undefined"
                                      @click="startStopArchive(currentDevice, stream.sourceToken, stream.dataChannel)"
                                      title="Start Recording">
                                <fa-icon v-if="recordingUpdate.find(_ => _ === stream.dataChannel) !== undefined"
                                         :icon="['fas', 'spinner']" spin style="padding: 0 7px;"/>
                                <span v-else>Start</span>
                            </b-button>
                        </div>
                    </div>
                </b-tab>

                <!-- DEVICE ERROR TAB -->
                <b-tab title="Errors">
                    <div v-if="deviceErrors.length > 0">
                        <div v-for="error in deviceErrors"
                             class="d-flex justify-content-between w-100" style="margin-bottom: 20px">
                            <div style="border: 1px solid black; background-color: #131313; width: 100%" class="rounded">
                                <div style="background-color: black;padding: 6px"
                                     class="d-flex justify-content-between align-items-center">
                                    <div style="font-weight: bolder">{{error.exceptionType}}</div>
                                    <div style="color: grey">{{(new Date(error.timeStamp)).toLocaleString()}}</div>
                                </div>
                                <div style="padding: 6px">
                                    <div>{{error.thrownFrom}}</div>
                                    <div style="height: 6px"/>
                                    <div style="color: grey">{{error.thrownException}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        No Errors on Device
                    </div>
                </b-tab>

            </b-tabs>

            <!-- FOOTER -->
            <template slot="modal-footer">
                <b-button size="sm" variant="warning" class="mr-auto" @click="openRebootModal()">Reboot</b-button>
                <b-button size="sm" variant="primary" @click="editInfo(currentDevice)">Edit Device</b-button>
                <b-button size="sm" variant="primary" @click="closeAllModals">OK</b-button>
            </template>

        </b-modal>

        <!-- TABLE -->
        <b-card style="width: 100%; height: 100%;">

            <!-- HEADER -->
            <div slot="header">
                <div class="d-flex w-100 justify-content-between">
                    <div class="d-flex align-items-center">
                        <h5 style="margin-bottom: 0"><b>Device Manager</b></h5>
                    </div>
                    <div class="d-flex align-items-center" style="gap: 8px;">
                        <div>
                            <b-input-group size="sm" prepend="Tenant">
                                <b-form-select size="sm" v-model="currentTenant" :options="tenantsDropdown"
                                               :style="'width:' + (mobile ? '75%' : 'fit-content')" @change="changeTableData">
                                </b-form-select>
                            </b-input-group>
                        </div>
                        <div v-if="currentTenant.name !== 'All Tenants' && currentTenant.name !== 'Loading...'">
                            <b-input-group size="sm" prepend="Edge">
                                <b-form-select size="sm" v-model="selectedEdgeDevice" :options="edgeDevicesDropdown[currentTenant._id]"
                                               :style="'width:' + (mobile ? '75%' : 'fit-content')" @change="filterByEdgeDevice">
                                </b-form-select>
                            </b-input-group>
                        </div>
                    </div>
                </div>
            </div>

            <!-- TABLE -->
            <v-client-table ref="dTable" style="width: 100%" :columns="(this.currentTenant.name === 'All Tenants' || this.currentTenant.name === 'Loading...') ? columns : ['hubName', 'friendlyName', 'ipAddress', 'deviceType', 'status', 'info']"
                            :data="tableDevices" :options="options" :theme="theme" class="dataTable">

                <!-- TENANT COLUMN -->
                <div slot="tenant" slot-scope="props" @click="setTenantInfo(capitalizeFirstLetter(getTenantName(props.row.tenantId)), props.row.hubName !== null ? props.row.hubName : '')" style="cursor: pointer;">
                    {{ capitalizeFirstLetter(getTenantName(props.row.tenantId) + (props.row.hubName !== null ? '/' + props.row.hubName : '')) }}
                </div>

                <!-- DEVICE NAME COLUMN -->
                <div slot="friendlyName" slot-scope="props">
                    {{ capitalizeFirstLetter(props.row.friendlyName) }}
                </div>

                <!-- IPADDRESS COLUMN -->
                <div slot="ipAddress" slot-scope="props">
                    <a :href="'http://' + props.row.ipAddress" target="_blank">{{ props.row.ipAddress }}</a>
                </div>

                <!-- INFO/EDIT COLUMN -->
                <div slot="h__info" style="float: right">Info/Edit</div>
                <div slot="info" slot-scope="props"
                     style="margin: -12px; display: flex; justify-content: flex-end; gap: 5px; transform: translateY(6px)">
                    <b-button variant="dark" size="sm" @click="showVideoModal(props.row)" class="iconButton"
                              v-if="props.row.deviceType ==='camera' &&  (props.row.status.status === 0 || props.row.status.status === 1)">
                        <fa-icon :icon="['fas', 'video']"/>
                    </b-button>
                    <b-button v-if="props.row.status.status !== -1" variant="dark" size="sm" class="iconButton" @click="moreInfo(props.row)">
                        <fa-icon :icon="['fas', 'info']" style="font-size: 20px; font-weight: bold"/>
                    </b-button>
                    <b-button v-else variant="dark" size="sm" class="iconButton" :href="'http://' + props.row.ipAddress" target="_blank">
                        <fa-icon :icon="['fas', 'mail-forward']"/>
                    </b-button>
                </div>

                <!-- STATUS COLUMN -->
                <div slot="status" slot-scope="props">
                    <b-badge v-if="props.row.status.status === 0" variant="success" size="lg">
                        Active
                    </b-badge>
                    <b-badge v-else-if="props.row.status.status === 1" variant="warning" size="lg">
                        Late
                    </b-badge>
                    <b-badge v-else-if="props.row.status.status === -1" variant="danger" size="lg">
                        Login Failure
                    </b-badge>
                    <b-badge v-else variant="danger" size="lg">
                        Offline
                    </b-badge>

                    <b-badge v-if="props.row.status.recording" variant="danger" size="lg" style="margin-left: 5px;">
                        Recording
                    </b-badge>
                </div>

                <!-- DEVICE TYPE COLUMN -->
                <div slot="deviceType" slot-scope="props">
                    {{ capitalizeFirstLetter(props.row.deviceType) }}
                </div>

            </v-client-table>

        </b-card>

    </div>
</template>

<script>
import Vue from 'vue'
import {ClientTable} from 'vue-tables-2';
import tenantStore from "@/store/tenantStore";
import deviceStore from "@/store/deviceStore";
import {isMobile} from 'mobile-device-detect';
import stream from "@/views/video_wall/stream_components/stream.vue";
import PubSub from 'pubsub-js';
import ScanDevices from "@/views/DeviceManager/ScanDevices";
import SmartSuiteDevice from "@/shared/smartsuite_services/smartsuite_device";
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import {findTimeZone, findReadableTimeZone, timezonesGeneric} from "../../shared/timezones";
import TextValueBox from "@/components/TextValueBox";
import CameraFeedModal from "@/views/DeviceManager/CameraFeedModal";
import MapCoordinatesPickerModal from "@/components/MapCoordinatesPickerModal.vue";
import googleMapSetup from '@/shared/mixins/googleMapSetup';
import gammaStore from "@/store/gammaStore";

Vue.use(ClientTable);

const SetHubName = 12;

export default {
    name: 'DeviceManager',
    mixins: [googleMapSetup],
    components: {
        MapCoordinatesPickerModal,
        CameraFeedModal,
        datePicker,
        ScanDevices,
        stream,
        TextValueBox
    },
    data: () => {
        return {

            // Data Table
            mobile: isMobile,
            columns: ['tenant', 'friendlyName', 'ipAddress', 'deviceType', 'status', 'info'],
            networkConfigOptions: [
                {value: 0, text: 'Automatic IP (DHCP) and DNS (DHCP)'},
                {value: 1, text: 'Automatic IP (DHCP) and manual DNS'},
                {value: 2, text: 'Manual IP and manual DNS'},
            ],
            ntpSettingsOptions: [
                {value: 0, text: 'Automatic date and time (NTP server using DHCP)'},
                {value: 1, text: 'Automatic date and time (manual NTP server)'},
                {value: 2, text: 'Custom date and time'},
            ],
            timezoneOptions: timezonesGeneric,
            deviceOptions: {
                automaticDateTime: false,
                cameraDate: null,
                cameraTime: null,
                NTPSettings: 0,
                NTPServerIp: '0.0.0.0',
                secondaryNTPServerIp: '0.0.0.0',
                networkConfigValue: 2,
                timeZone: null,
                domainNames: [''],
                primaryDNSServer: '0.0.0.0',
                secondaryDNSServer: '0.0.0.0',
                ipAddress: '0.0.0.0',
                subnetMask: '255.255.255.0',
                defaultRouter: '192.168.0.1',
                latitude: 0,
                longitude: 0
            },
            options: {
                orderBy: {column: 'tenant', ascending: true},
                headings: {
                    tenant: 'Tenant',
                    friendlyName: 'Name',
                    ipAddress: 'IP',
                    deviceType: 'Device Type',
                    status: 'Status',
                    info: 'Info/Edit',
                    hubName: 'Edge Service'
                },
                sortable: ['tenant', 'friendlyName', 'ipAddress', 'deviceType', 'status', 'hubName'],
                filterable: ['tenant', 'friendlyName', 'ipAddress', 'deviceType', 'status', 'hubName'],
                sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                initialPage: 1,
                pagination: {
                    chunk: 5,
                    edge: false,
                    nav: 'scroll'
                },
                perPage: 25,
                skin: 'table table-striped table-hover',
                customSorting: {
                    ipAddress: function (ascending) {
                        return function (a, b) {
                            let aArray = a.ipAddress.split('.').map(Number);
                            let bArray = b.ipAddress.split('.').map(Number);
                            for (let i = 0; i < 4; i++) {
                                if (aArray[i] < bArray[i]) {
                                    return ascending === true ? -1 : 1;
                                } else if (aArray[i] > bArray[i]) {
                                    return ascending === true ? 1 : -1;
                                }
                            }
                            return 0;
                        }
                    }
                }
            },
            theme: 'bootstrap4',

            // Modal Variables
            videoModal: false,
            openMoreInfo: false,
            openEditInfo: false,

            // Tenant Variables
            tenants: [],
            tenantsDropdown: [],
            edgeDevicesDropdown: {},
            currentTenant: {
                name: "Loading..."
            },

            // Other Variables
            geoLocationMapModal: false,
            curDeviceFound: false,
            pubsubs: [],
            liveStream: {
                modal: false,
                sourceToken: '0',
                device: null,
                sensors: [],
                ready: false
            },
            recordingUpdate: [],
            currentDeviceReady: false,
            devices: [],
            currentDevice: new SmartSuiteDevice({}),
            deviceErrors: [],
            rebootModal: false,
            deviceRebooting: false,
            selectedEdgeDevice: {
                name: 'All'
            },
            editEdgeDeviceNameModal: false,
            displayTenantInfoModal: false,
            newEdgeDeviceName: '',
            selectedTenantName: '',
            selectedEdgeDeviceName: ''
        }
    },
    async created() {
        // Get Tenants
        this.tenants = await tenantStore.dispatch('getTenants').catch(error => {
            console.error(error);
            this.$mToast({
                title: error.response.status + ' Error',
                text: "Tenant couldn't be retrieved: " + error.response.statusText,
                style: 'error'
            });
        });
        for (const tenant of this.tenants) {
            this.pubsubs.push(PubSub.subscribe('edgeDevicesUpdate', async (msg, data) => {
                if (data.tenantId === tenant._id) {
                    if (data.edgeDevices) {
                        if (this.edgeDevicesDropdown[tenant._id] === undefined) {
                            Vue.set(this.edgeDevicesDropdown, tenant._id, []);
                            this.edgeDevicesDropdown[tenant._id].push(Object.assign({}, {
                                text: "All",
                                value: {name: "All"}
                            }));
                        }
                        for (const edgeDevice of data.edgeDevices) {
                            if (this.edgeDevicesDropdown[tenant._id].find(storedEdgeDevice => storedEdgeDevice.value.name === edgeDevice.name) === undefined) {
                                this.edgeDevicesDropdown[tenant._id].push({
                                    text: edgeDevice.friendlyName,
                                    value: {name: edgeDevice.name}
                                });
                            }
                            let devices = await deviceStore.dispatch('getSmartSuiteDevicesByEdgeDevice', {
                                edgeDevice: edgeDevice.name,
                                tenantId: tenant._id,
                                isConnected: true
                            });
                            this.updateDevices(devices);
                        }
                    }
                }
            }));
            await deviceStore.dispatch('setMediahubListener', tenant._id);
            this.pubsubs.push(PubSub.subscribe('ssdevicesupdate-' + tenant._id, (msg, data) => {
                this.updateDevices(data);
            }));
        }
        await this.getAllDevices();
    },
    mounted() {
        this.mapOptions = {
            ...this.mapOptions,
            mapTypeId: 'hybrid',
            disableDefaultUI: true
        };
    },
    async beforeDestroy() {
        await deviceStore.dispatch('clearSourceListeners');
        this.pubsubs.forEach(sub => {
            PubSub.unsubscribe(sub);
        });
    },
    methods: {
        async renameEdgeDevice() {
            let mediahub = await gammaStore.dispatch('getMediahub');
            await mediahub.sendMessage('sendusermessage', {
                CommandRequest: SetHubName,
                HubName: this.selectedEdgeDeviceName,
                FriendlyName: this.newEdgeDeviceName,
                HubCommandChannel: this.selectedEdgeDeviceName + '_command'
            });
            await this.closeRenameEdgeDeviceModal();
            await this.closeTenantInfoModal();
        },
        openRenameEdgeDeviceModal() {
            this.editEdgeDeviceNameModal = true;
        },
        closeRenameEdgeDeviceModal() {
            this.editEdgeDeviceNameModal = false;
            this.newEdgeDeviceName = '';
        },
        closeTenantInfoModal() {
            this.displayTenantInfoModal = false;
            setTimeout(() => {
                this.selectedTenantName = '';
                this.selectedEdgeDeviceName = '';
            }, 250);
        },
        setTenantInfo(tenantName, edgeName) {
            this.selectedTenantName = tenantName;
            this.selectedEdgeDeviceName = edgeName;
            this.displayTenantInfoModal = true;
        },
        filterByEdgeDevice() {
            this.$refs.dTable.setPage(1);
        },
        applyLatLng(point) {
            this.currentDevice.getDevice().latitude = point.lat.toString();
            this.currentDevice.getDevice().longitude = point.lng.toString();
        },
        openMap() {
            this.geoLocationMapModal = true;
        },
        openRebootModal() {
            this.rebootModal = true;
        },
        async rebootDevice() {
            await this.currentDevice.reboot(this.currentDevice);
            this.deviceRebooting = true;
            //no current feedback from device
            setTimeout(() => {
                this.rebootModal = false;
                this.openMoreInfo = false;
                this.deviceRebooting = false;
            },3000);
        },
        findTimeZoneNew(ele) {
            return findReadableTimeZone(ele);
        },
        addDomainName() {
            this.deviceOptions.domainNames.push('');
        },
        removeDomainName(index) {
            this.deviceOptions.domainNames.splice(index, 1);
        },
        fillSystemSettings() {
            this.deviceOptions = Object.assign({}, {
                automaticDateTime: false,
                cameraDate: null,
                cameraTime: null,
                NTPSettings: 0,
                NTPServerIp: null,
                secondaryNTPServerIp: null,
                networkConfigValue: 2,
                timeZone: null,
                domainNames: [''],
                primaryDNSServer: null,
                secondaryDNSServer: null,
                ipAddress: null,
                subnetMask: null,
                defaultRouter: null,
                latitude: 0,
                longitude: 0
            });
            // determine if the ip and dns are obtained automatically
            this.deviceOptions.networkConfigValue = this.currentDevice.getNetworkConfig();
            //fill ip information
            this.deviceOptions.ipAddress = this.currentDevice.getIpAddress();
            this.deviceOptions.defaultRouter = this.currentDevice.getDefaultRouter();
            if (this.currentDevice.getMask() === 24) {
                this.deviceOptions.subnetMask = '255.255.255.0';
            }
            //determine if the date/time is coming from ntp settings
            this.deviceOptions.NTPSettings = this.currentDevice.getNTPSetting();
            //input manual NTP server for date/time
            if (this.currentDevice.isManualNTP() !== null) {
                this.deviceOptions.NTPServerIp = this.currentDevice.getNTP(0);
                if (this.currentDevice.isManualNTP().length > 1) {
                    this.deviceOptions.secondaryNTPServerIp = this.currentDevice.getNTP(1);
                }
            }
            //construct date/time
            this.deviceOptions.cameraDate = this.currentDevice.getDeviceDate();
            this.deviceOptions.cameraTime = this.currentDevice.getDeviceTime();
            //input timezone
            let timezone = findTimeZone(this.currentDevice.getTimeZone());
            this.deviceOptions.timeZone = timezone.value;
            //input search domains
            if (this.currentDevice.getDomainNames() !== null) {
                this.deviceOptions.domainNames = [];
                this.currentDevice.getDomainNames().forEach(domainName => {
                    this.deviceOptions.domainNames.push(domainName);
                });
            }
            //input DNS servers
            if (this.currentDevice.isManualDNS() !== null) {
                this.deviceOptions.primaryDNSServer = this.currentDevice.getDNS(0);
                if (this.currentDevice.isManualDNS().length > 1) {
                    this.deviceOptions.secondaryDNSServer = this.currentDevice.getDNS(1);
                }
            }

            //get geo-location
            this.deviceOptions.latitude = this.currentDevice.getLatitude();
            this.deviceOptions.longitude = this.currentDevice.getLongitude();
        },
        async showVideoModal(row) {
            let device = await deviceStore.dispatch('getDevice', {
                tenantId: row.tenantId,
                deviceId: row.deviceId
            });
            this.liveStream.device = device;
            this.liveStream.sensors = device.getMultiSensorStreams().map(_ => {
                return {text: _.friendlyName, value: _.sourceToken}
            });
            this.liveStream.sourceToken = '0';
            this.liveStream.modal = true;
            this.liveStream.ready = true;
        },
        hideVideoModal() {
            this.liveStream.device = null;
            this.liveStream.sensors = [];
            this.liveStream.sourceToken = '0';
            this.liveStream.modal = false;
            this.liveStream.ready = false;
        },
        compareTenants(a, b) {
            if (a.text < b.text) {
                return -1;
            } else {
                return 1;
            }
        },
        startStopArchive(device, sourceToken, dataChannel) {
            this.recordingUpdate.push(dataChannel);
            if (device.isRecording()) {
                device.stopArchive(sourceToken, dataChannel);
            } else {
                device.startArchive(sourceToken, dataChannel);
            }
        },
        //FCR and Axios
        async getAllDevices() {
            this.devices = [];
            this.tenantsDropdown.push(Object.assign({}, {
                text: "Loading...",
                value: {name: "Loading..."}
            }));

            // Looping Through Each Tenant
            for (const tenant of this.tenants) {
                //get all the edge services a user has access to
                await deviceStore.dispatch('getEdgeDevices', tenant);
                this.tenantsDropdown.push({  // tenant array for dropdown in table header
                    value: {
                        _id: tenant._id,
                        name: tenant.tenantName
                    },
                    text: tenant.tenantName
                });

                // Get Device Updates For Tenant
                this.pubsubs.push(PubSub.subscribe('ssdevicesupdate-' + tenant._id, (msg, data) => {
                    this.updateDevices(data);
                }));

                // Get Device Error Updates For Tenant
                this.pubsubs.push(PubSub.subscribe('deviceerrorsupdate-' + tenant._id, async (msg, data) => {
                    this.deviceErrors = await this.currentDevice.getDeviceErrors();
                }));

                // Make sure table is at page 1
                this.$refs.dTable.setPage(1);

                // Get Login Failures
                let loginFailures = await deviceStore.dispatch('getLoginFailures', tenant._id);
                this.devices = this.devices.concat(loginFailures);
                this.pubsubs.push(PubSub.subscribe('loginfailuresupdate-' + tenant._id, (msg, data) => {
                    this.updateDevices(data);
                }));
            }
            this.tenantsDropdown.splice(this.tenantsDropdown.findIndex(_ => _.text === "Loading..."), 1);
            // Adding All Tenant Option For dropdown
            this.tenantsDropdown.push(Object.assign({}, {
                text: "All Tenants",
                value: {name: "All Tenants"}
            }));
            this.currentTenant = {name: "All Tenants"};

            // Sorting Tenant Arrays by Name
            this.tenantsDropdown.sort(this.compareTenants);
        },
        updateDevices(devices) {
            if (!this.openEditInfo && !this.liveStream.ready && devices) {
                devices.map(device => {
                    if (this.$route.query.id && !this.curDeviceFound
                        && parseInt(this.$route.query.tenantId) === device.getTenantId()
                        && this.$route.query.hubName === device.getHubName()
                        && this.$route.query.id === device.getDeviceId()) {
                        this.curDeviceFound = true;
                        this.moreInfo({
                            friendlyName: device.getDeviceName(),
                            ipAddress: device.getIpAddress(),
                            deviceType: device.getDeviceType(),
                            status: {status: device.getDetectionStatus(), recording: device.isRecording()},
                            deviceId: device.getDeviceId(),
                            tenantId: device.getTenantId(),
                            hubName: device.getHubName()
                        });
                    }
                    if (this.currentDevice.getDeviceId() === device.getDeviceId()) {
                        this.currentDevice.getArchiveStreams().forEach(stream1 => {
                            device.getArchiveStreams().forEach(stream2 => {
                                if (stream1.dataChannel === stream2.dataChannel) {
                                    if (stream1.isRunning === stream2.isRunning) {
                                        let index = this.recordingUpdate.findIndex(dataChannel => {
                                            return dataChannel === stream1.dataChannel
                                        });
                                        if (index !== -1) {
                                            this.recordingUpdate.splice(index, 1);
                                        }
                                    }
                                }
                            });
                        });
                        this.currentDevice.updateDevice(device.device);
                    }
                    let update = this.devices.findIndex(_ => _.getDeviceId() === device.getDeviceId());
                    if (update !== -1) {
                        this.devices[update].updateDevice(device.device);
                    } else {
                        this.devices.push(device);
                    }
                });
                if (Object.keys(this.currentDevice.getDevice()).length !== 0) {
                    this.fillSystemSettings();
                }
            }
        },
        async updateDeviceData() {
            await this.currentDevice.modifyDevice(this.currentDevice).then(response => {
                this.updateDevices([response]);
                this.$mToast({
                    title: 'Success',
                    text: "Device updated",
                    style: 'success'
                });
            }).catch(error => {
                console.error(error);
                this.$mToast({
                    title: error.response.status + ' Error',
                    text: "Device couldn't be updated: " + error.response.statusText,
                    style: 'error'
                });
            });
            this.closeAllModals();
        },
        cancelEdit() {
            this.openMoreInfo = true;
            this.openEditInfo = false;
        },
        async moreInfo(row) {
            let device = await deviceStore.dispatch('getDevice', {
                tenantId: row.tenantId,
                deviceId: row.deviceId
            });
            this.currentDeviceReady = false;
            this.currentDevice = device;
            this.openMoreInfo = true;
            this.openEditInfo = false;
            this.fillSystemSettings();
            this.deviceErrors = await this.currentDevice.getDeviceErrors();
        },
        editInfo() {
            this.openMoreInfo = false;
            this.openEditInfo = true;
            this.currentDevice = new SmartSuiteDevice(this.currentDevice.getDevice());
            if (this.currentDevice.deviceType === 'camera') {
                this.currentDeviceReady = true;
            }
        },
        editEnterButton() {
            if (Boolean(this.currentDevice.ipAddress) && Boolean(this.currentDevice.tenantId)) {
                this.openEditInfo = false;
                this.updateDeviceData();
            }
        },
        capitalizeFirstLetter(string) {
            if (string) {
                return string.charAt(0).toUpperCase() + string.slice(1);
            } else {
                return '';
            }
        },
        closeAllModals() {
            this.openMoreInfo = false;
            this.openEditInfo = false;
            this.currentDeviceReady = false;
        },
        changeTableData() {
            this.$refs.dTable.setPage(1);
        },
        convertToNumber(ele) {
            if (typeof ele === 'number') {
                return ele;
            }
            return parseFloat(ele);
        },
        getTenantName(tenantId) {
            let tenant = this.tenants.find(tenant => tenant._id === tenantId.toString())
            if (tenant !== undefined) {
                return tenant.tenantName;
            } else {
                return '';
            }
        },
        getHubFriendlyName(hubName, tenantId) {
            try {
                let foundHub = this.edgeDevicesDropdown[tenantId].find(edgeDevice => edgeDevice.value.name === hubName);
                return foundHub.text;
            } catch (e) {
                return null;
            }
        }
    },

    computed: {
        curDeviceCoordinates() {
            if (!this.currentDevice || !this.currentDevice.getDevice().latitude || !this.currentDevice.getDevice().longitude) {
                return {lat: null, lng: null};
            }
            return {
                lat: this.convertToNumber(this.currentDevice.getDevice().latitude),
                lng: this.convertToNumber(this.currentDevice.getDevice().longitude),
            };
        },
        displayedDevices() {
            if (this.currentTenant.name === 'All Tenants' || this.currentTenant.name === 'Loading...') {
                return this.devices;
            }
            if (this.selectedEdgeDevice.name === 'All') {
                return this.devices.filter(_ => this.getTenantName(_.getTenantId()) === this.currentTenant.name);
            }
            return this.devices.filter(_ => this.getTenantName(_.getTenantId()) === this.currentTenant.name &&
                                        _.getHubName() === this.selectedEdgeDevice.name)
        },
        tableDevices() {
            return this.displayedDevices.map(obj => {
                return {
                    friendlyName: obj.getDeviceName(),
                    ipAddress: obj.getIpAddress(),
                    deviceType: obj.getDeviceType(),
                    status: {status: obj.getDetectionStatus(), recording: obj.isRecording()},
                    deviceId: obj.getDeviceId(),
                    tenantId: obj.getTenantId(),
                    hubName: this.getHubFriendlyName(obj.getHubName(), obj.getTenantId())
                };
            });
        }
    }
}
</script>

<style scoped>
    .flexElement {
        flex: 50%;
        padding: 5px;
    }
    .flexElementFull {
        flex: 100%;
        padding: 5px;
    }
    .mFieldsetTitle {
        background-color: #1a1a1a;
        width: fit-content;
        transform: translate(5px, 4px);
        margin: 0;
        position: absolute;
        padding: 0 5px;
        display: block;
        font-size: 1.09375rem;
        font-weight: bolder;
    }
    .mFieldset {
        border-radius: 5px;
        border: 2px groove rgb(192, 192, 192);
        padding: 12px 10px 10px 10px;
        margin-top:  15px;
    }
    .iconButton {
        height: 29px !important;
        width: 29px !important;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .iconButton:not(:hover) {
        background-color: #131313 !important;
    }
</style>