<template>
    <b-modal hide-header hide-footer no-close-on-backdrop no-body no-close-on-esc
             class="modal-dark" size="lg" v-model="value">
        <div style="margin: -16px">
            <gmap-map ref="myMap" :center="oldPoint || center" :options="mapOptions" :zoom="oldPoint ? 16 : 11"
                      style="height: 500px; width: 100%;"
                      @click="placePoint($event.latLng)"
                      @mousemove="updatePoint($event.latLng)">
                <gmap-marker :position="point" v-if="point && !placingPoint" :draggable="true" @drag="onMarkerDrag"
                             @dragend="onMarkerDragEnd" @dragstart="draggingPoint = true" :title="readableLocation"
                             :icon="mtopMarker"/>
                <gmap-marker :position="unplacedPoint" v-if="placingPoint && unplacedPoint" :icon="mtopMarker"/>
            </gmap-map>
            <div class="d-flex justify-content-between" style="margin: 10px">
                <b-button size="sm" @click="$emit('input', false)" style="width: 56px">Cancel</b-button>
                <div class="d-flex" style="gap: 5px">
                    <b-button v-if="oldPoint" size="sm" variant="secondary" @click="revertPoint()"
                              :disabled="placingPoint || draggingPoint || !pointChanged">
                        Revert
                    </b-button>
                    <b-button size="sm" variant="primary" @click="placingPoint = !placingPoint"
                              :disabled="placingPoint || draggingPoint">
                        <fa-icon :icon="['fas', 'location-dot']"/> Place Point
                    </b-button>
                </div>
                <b-button size="sm" variant="primary" @click="apply()" style="width: 56px" :disabled="placingPoint">Apply</b-button>
            </div>
        </div>
    </b-modal>
</template>

<script>
import googleMapSetup from '@/shared/mixins/googleMapSetup';
export default {
    name: "MapModal",
    mixins: [googleMapSetup],
    props: {
        value: Boolean,
        oldLat: Number,
        oldLng: Number
    },
    data: () => {
        return {
            center: {
                lat: 42.2711,
                lng: -89.0940
            },
            unplacedPoint: null,
            placingPoint: false,
            point: null,
            oldPoint: null,
            draggingPoint: false,
        }
    },
    created() {
        if (this.oldLat && this.oldLng) {
            this.oldPoint = this.point = {
                lat: this.oldLat,
                lng: this.oldLng
            }
        }
    },
    mounted() {
        this.mapOptions = {
            ...this.mapOptions,
            scrollwheel: true,
            mapTypeId: 'hybrid',
        }
    },
    methods: {
        apply() {
            this.$emit('input', false)
            if (this.point) {
                this.$emit('pointSelected', this.point);
            }
        },
        updatePoint(latLng) {
            if (this.placingPoint) {
                this.unplacedPoint = {
                    lat: latLng.lat(),
                    lng: latLng.lng(),
                };
            }
        },
        placePoint(latLng) {
            if (this.placingPoint) {
                this.unplacedPoint = false
                this.point = {
                    lat: latLng.lat(),
                    lng: latLng.lng(),
                };
                this.placingPoint = false
            }
        },
        onMarkerDragEnd(event) {
            this.onMarkerDrag(event)
            this.draggingPoint = false
        },
        onMarkerDrag(event) {
            this.point = {
                lat: event.latLng.lat(),
                lng: event.latLng.lng()
            };
        },
        revertPoint() {
            this.point.lat = this.oldPoint.lat;
            this.point.lng = this.oldPoint.lng;
        }
    },
    computed: {
        pointChanged() {
            return this.oldPoint.lat !== this.point.lat || this.oldPoint.lng !== this.point.lng
        },
        readableLocation() {
            return 'Latitude: ' + this.point.lat + '\nLongitude: ' + this.point.lng
        },
    }
}
</script>

<style scoped>

</style>