<template>
    <div>
        <canvas :id="`video_canvas2_${sourceId}`"
                style="position: absolute; top: 100%; left: 100%; z-index: 500;"
                :style="`cursor: ${mouseStyle}`"
                @mousedown="mouseDown" @mouseup="mouseUp" @mouseleave="mouseLeave"
                @mouseenter="mouseEnter" @mousemove="mouseMove" @wheel="mouseWheel"/>
    </div>
</template>

<script>
export default {
    name: "MiniStreamWindow",
    props: {
        device: {
            type: Object,
            required: true
        },
        sourceId: {
            type: String,
            required: true
        }
    },
    data: () => {
        return {
            insideRedBox: false,
            isMouseDown : false,
            mouseCoords: {x: 0, y: 0},
            mouseStyle: 'default'
        }
    },
    methods : {
        mouseDown(e) {
            this.isMouseDown = true;
            //TODO decide if we want static jump from each corner for the miniplayer or a super dynamic draggable box
            /*let currentCorner = this.device.getCanvasCorner(this.sourceId);
            let canvas = document.getElementById('video_canvas2_' + this.sourceId);
            if (this.mouseCoords.x < canvas.width * .05 && this.mouseCoords.y < canvas.height * .05 && currentCorner !== 0) {
                //move to top left
            } else if (this.mouseCoords.x > canvas.width * .95 && this.mouseCoords.y < canvas.height * .05 && currentCorner !== 1) {
                //move to top right
            } else if (this.mouseCoords.x < canvas.width * .05 && this.mouseCoords.y > canvas.height * .95 && currentCorner !== 2) {
                //move to bottom left
            } else if (this.mouseCoords.x > canvas.width * .95 && this.mouseCoords.y > canvas.height * .95 && currentCorner !== 3) {
                //move to bottom right
            }*/
        },
        mouseUp() {
            this.isMouseDown = false;
            this.insideRedBox = false;
            if (this.insideRedBox === true) {
                this.mouseStyle = 'grab';
            } else {
                this.mouseStyle = 'default';
            }
        },
        mouseLeave() {
            this.isMouseDown = false;
            this.insideRedBox = false;
            this.mouseStyle = 'default';
            this.device.setShowMovePoints(this.sourceId, false);
        },
        mouseEnter() {
            this.mouseStyle = 'default';
            this.device.setShowMovePoints(this.sourceId, true);
        },
        mouseMove(e) {
            let zoomScale = 1 + (this.device.getCanvasZoom(this.sourceId) * this.device.getZoomIncrement());
            let differenceX = this.mouseCoords.x - e.layerX;
            let differenceY = this.mouseCoords.y - e.layerY;
            let miniCoord = this.device.getCanvasMiniCoords(this.sourceId);
            this.insideRedBox = (e.layerX > miniCoord.miniXStart && e.layerX < miniCoord.miniXEnd &&
                e.layerY > miniCoord.miniYStart && e.layerY < miniCoord.miniYEnd);
            if (this.isMouseDown === true && this.insideRedBox === true) {
                this.mouseStyle = 'grabbing';
                this.device.dragCanvas(this.sourceId, -(differenceX * (this.device.getMiniCanvasScale() * zoomScale)), -(differenceY * (this.device.getMiniCanvasScale() * zoomScale)));
            } else if (this.insideRedBox === true) {
                this.mouseStyle = 'grab';
            } else {
                this.mouseStyle = 'default';
            }
            this.mouseCoords = {x: e.layerX, y: e.layerY};
        },
        mouseWheel(e) {
            if (e.deltaY > 0) {
                this.device.zoomOut(this.sourceId, e.layerX, e.layerY);
            } else if (e.deltaY < 0) {
                //mouse scroll up, zoom in
                this.device.zoomIn(this.sourceId, e.layerX, e.layerY);
            }
        }
    }
}
</script>

<style scoped>

</style>