<template>
    <div :id="'container-' + sourceId" style="background-color: black">
        <div v-if="buffering === true" class="w-100 h-100 d-flex justify-content-center align-items-center"
             style=" z-index: 1; position: absolute">
            <Ellipsis width="10%"/>
        </div>
        <div :id="'stream-' + sourceId" ref="stream"/>
    </div>
</template>

<script>
    import Vue from "vue";
    import deviceStore from "@/store/deviceStore";
    import PubSub from "pubsub-js";
    import Ellipsis from "@/components/loading_icons/ellipsis.vue";

    export default {
        name: "stream",
        props: {
            archiveOnly: {
                type: Boolean,
                default: false
            },
            device: {
                type: Object
            },
            sourceToken: {
                type: String,
                default: '0'
            },
            dataChannel: {
                type: String,
                default: undefined
            },
            quality: {
                type: Number,
                default: deviceStore.getters.getQuality
            },
            isInfinityGrid: {
                type: Boolean,
                default: true
            }
        },
        components: {
            Ellipsis
        },
        data() {
            return {
                isPlayerCreated: false,
                qualityPubSub: null,
                pubsubs: [],
                buffering: false,
                isAnimating: false
            }
        },
        async created() {
            await this.construct(this.quality);
            this.pubsubs.push(PubSub.subscribe('quality-change-' + this.sourceId, (msg, data) => {
                this.changeQuality(data);
            }));
            this.pubsubs.push(PubSub.subscribe('videoBuffering' + this.sourceId, (msg, data) => {
                this.buffering = data;
            }));
            this.pubsubs.push(PubSub.subscribe('startPlaybackRequest' + this.sourceId, (msg, data) => {
                // data = {request, isContinuous}
                this.constructPlayback(data);
            }));
        },
        async beforeDestroy() {
            this.isAnimating = false;
            await this.destruct();
            this.pubsubs.forEach(sub => {
                PubSub.unsubscribe(sub);
            });
        },

        methods: {
            async wipeStream() {
                await this.destruct();
                let temp = document.getElementById('stream-' + this.sourceId);
                if (temp) {
                    temp.innerHTML = '';
                }
            },
            async changeQuality(quality) {
                await this.wipeStream();
                await this.construct(quality, true);
            },
            async constructPlayback(data) {
                await this.wipeStream();
                await this.construct(undefined, undefined, data)
            },
            async construct(quality = 0, qualityChangeBoolean, playbackRequest) {
                if (this.isPlayerCreated === true) {
                    await this.destruct();
                }

                /* Create Jmux Player */
                this.device.createPlayer(this.device, quality, this.sourceToken, this.dataChannel, this.archiveOnly === true);
                this.isPlayerCreated = true;

                /* Display Stream */
                Vue.nextTick().then(async function () {
                    const streamElement = document.getElementById("stream-" + this.sourceId);
                    if (streamElement !== null) {
                        await streamElement.appendChild(this.device.createInstance(this.sourceToken, this.dataChannel));
                        this.device.resetVideoSize(this.sourceId, qualityChangeBoolean === true, !this.isInfinityGrid);

                        if (this.archiveOnly === true) {
                            this.device.changeDraggable(this.sourceId, true);
                        }
                        if (this.archiveOnly === false) {
                            if (playbackRequest === undefined) {
                                this.device.startPlayer(this.sourceId);
                            } else {
                                this.device.startPlayback(this.sourceId, playbackRequest.request, playbackRequest.isContinuous);
                            }
                        } else if (playbackRequest !== undefined) {
                            this.device.startPlayback(this.sourceId, playbackRequest.request, playbackRequest.isContinuous);
                        }
                    }

                    // Start the animation using requestAnimationFrame
                    this.isAnimating = true;
                    const animate = () => {
                        if (!this.isAnimating) {return;} // Stop animation if needed
                        this.device.displayDecodedVideo(this.sourceId, !this.isInfinityGrid);
                        requestAnimationFrame(animate); // Schedule the next frame
                    };
                    requestAnimationFrame(animate);
                }.bind(this));
                this.$emit('madePlayer', this.sourceId);
            },
            async destruct() {
                await this.device.destroyPlayer(this.sourceId);
                this.isPlayerCreated = false;
                this.isAnimating = false;
            },
        },
        computed: {
            sourceId() {
                return this.device.getDeviceId() + this.sourceToken + this.dataChannel;
            }
        }
    }
</script>

<style>
/*video::-webkit-media-controls-play-button, video::-webkit-media-controls-pausebutton {
    display: none;
}*/
</style>